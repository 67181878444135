<template>
  <CRow class="w-100 justify-content-center">
    <CCard class="w-75">
      <CCardHeader>
        <div class="card-header-action">
          <h2 v-if="is_edit_offers">Оновлення комерційної пропозиції</h2>
          <h2 v-else>Комерційна пропозиція</h2>
          <p v-if="order !== null" class="mb-0">ID тендеру: <span class="font-weight-bold">{{ order.id }}</span></p>
          <p v-if="order !== null"><span class="font-weight-bold">{{ order.branch_a }}</span>
            {{ order.branch_a_address }}</p>
        </div>
        <CAlert v-if="failed" show color="danger">{{ failed_message }}</CAlert>

      </CCardHeader>
      <CCardBody>
        <CForm
            @submit.prevent="createOffer"
            novalidate
        >
          <CCardGroup>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.offers.connection_cost.$error}">
                <CCardHeader>
                  Вартість підключення, грн з ПДВ <br><span style="font-size: .7rem;">Ціну вказувати з урахуванням обладнання, якщо продаж</span>
                </CCardHeader>
                <CInput
                    v-c-tooltip="{
                                    content: 'З урахуванням обладнання',
                                    placement: 'top'
                                }"
                    class="mb-0"
                    :class=" {
                      'error': $v.offers.connection_cost.$error
                    }"
                    placeholder="З урахуванням обладнання"
                    v-model="offers.connection_cost"
                    @change="$v.offers.connection_cost.$touch(), $v.offers.connection_cost.$touch()"
                >
                </CInput>
                <small v-if="!$v.offers.connection_cost.required" class="form-text errorText w-100">Введіть
                  вартість підключення</small>
                <small v-if="!$v.offers.connection_cost.or" class="form-text errorText w-100">Введіть, будь ласка, ціле
                  число або дробове з розділювачем "." (100 чи 100.00)</small>
                <small class="form-text errorText w-100" v-if="!$v.offers.connection_cost.maxLength">Максимальна
                  кількістьсть цифр
                  ({{ $v.offers.connection_cost.$params.maxLength.max }}) !</small>
              </div>
            </CCard>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.offers.monthly_payment.$error}">
                <CCardHeader>
                  Щомісячний платіж, грн з ПДВ <br><span style="font-size: .7rem;">Ціну вказувати з урахуванням обладнання, якщо оренда</span>
                </CCardHeader>
                <CInput
                    v-c-tooltip="{
                                    content: 'З урахуванням обладнання',
                                    placement: 'top'
                                }"
                    placeholder="З урахуванням обладнання"
                    required
                    :class=" {
                      'error': $v.offers.monthly_payment.$error
                    }"
                    class="mb-0"
                    v-model="offers.monthly_payment"
                    @change="$v.offers.monthly_payment.$touch(), $v.offers.monthly_payment.$touch()"
                >
                </CInput>
                <small v-if="!$v.offers.monthly_payment.required" class="form-text errorText w-100">Введіть вартість
                  щомісячного підключення</small>
                <small v-if="!$v.offers.monthly_payment.or" class="form-text errorText w-100">Введіть, будь ласка, ціле
                  число або дробове з розділювачем "." (100 чи 100.00)</small>
                <small class="form-text errorText w-100" v-if="!$v.offers.monthly_payment.maxLength">Максимальна
                  кількістьсть цифр
                  ({{ $v.offers.monthly_payment.$params.maxLength.max }}) !</small>
              </div>
            </CCard>
          </CCardGroup>
          <hr>
          <CCardGroup>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.offers.download_speed.$error}">
                <CCardHeader>
                  Швидкість на прийом, мбіт/с. <span style="font-size: 0.8rem">(Європа)</span>
                </CCardHeader>
                <multiselect
                    :class=" {
                      'error': $v.offers.download_speed.$error
                    }"
                    placeholder="Зробіть вибір"
                    class="float-left pr-0"
                    :options="[1,2,5,10,20,50,100,200,'Інше']"
                    @change="changeDownloadSpeed(offers.download_speed), $v.offers.download_speed.$touch()"
                    :show-labels="false"
                    v-model="offers.download_speed"
                />
                <small v-if="!$v.offers.download_speed.required" class="form-text errorText w-100">Виберіть швидкість
                  підключення</small>
                <CInput
                    class="mt-2"
                    placeholder="Введіть швидкість"
                    v-if="offers.download_speed === 'Інше'"
                    v-model="new_download_speed"
                >
                </CInput>
              </div>
            </CCard>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.offers.upload_speed.$error}">
                <CCardHeader>
                  Швидкість на віддачу, мбіт/с. <span style="font-size: 0.8rem">(Європа)</span>
                </CCardHeader>
                <multiselect
                    :class=" {
                      'error': $v.offers.upload_speed.$error
                    }"
                    class="float-left pr-0"
                    placeholder="Зробіть вибір"
                    :options="[1,2,5,10,20,50,100,200,'Інше']"
                    :show-labels="false"
                    @change="changeUploadSpeed(offers.upload_speed), $v.offers.upload_speed.$touch()"
                    v-model="offers.upload_speed"
                />
                <small v-if="!$v.offers.upload_speed.required" class="form-text errorText w-100">Виберіть швидкість
                  підключення</small>
                <CInput
                    class="mt-2"
                    placeholder="Введіть швидкість"
                    v-if="offers.upload_speed === 'Інше'"
                    v-model="new_upload_speed"
                >
                </CInput>
              </div>
            </CCard>
          </CCardGroup>
          <hr>
          <CCardGroup>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.offers.type_channel.$error}">
                <CCardHeader>
                  Тип каналу
                </CCardHeader>
                <multiselect
                    :class=" {
                      'error': $v.offers.type_channel.$error
                    }"
                    placeholder="Зробіть вибір"
                    :show-labels="false"
                    :options="['КПД L2','Інтернет','Інше']"
                    @change="showTypeChannel(offers.type_channel), $v.offers.type_channel.$touch()"
                    v-model="offers.type_channel"
                >
                </multiselect>
                <small v-if="!$v.offers.type_channel.required" class="form-text errorText w-100">Виберіть тип
                  каналу</small>
                <CInput
                    v-if="offers.type_channel === 'Інше'"
                    placeholder="Введіть тип каналу"
                    class="mt-2"
                    v-model="new_connect_type"
                >
                </CInput>
              </div>
              <div class="form-item" :class="{'errorInput': $v.changeGenerals.physics_id.$error}">
                <CCardHeader>
                  Фізика
                  <CSpinner v-if="!$store.getters.getPhysicsLength" color="primary" size="sm"/>
                </CCardHeader>
                <multiselect
                    :class=" {
                      'error': $v.changeGenerals.physics_id.$error
                    }"
                    @change="$v.changeGenerals.physics_id.$touch()"
                    placeholder="Зробіть вибір"
                    :show-labels="false"
                    :options="Object.values($store.getters.getPhysics)"
                    v-model="changeGenerals.physics_id"
                >
                </multiselect>
                <small v-if="!$v.changeGenerals.physics_id.required" class="form-text errorText w-100">Виберіть
                  фізику</small>
              </div>
            </CCard>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.changeGenerals.iface_id.$error}">
                <CCardHeader>
                  Інтерфейс
                  <CSpinner v-if="!$store.getters.getInterfaceLength" color="primary" size="sm"/>
                </CCardHeader>
                <multiselect
                    :class=" {
                      'error': $v.changeGenerals.iface_id.$error
                    }"
                    @change="$v.changeGenerals.iface_id.$touch()"
                    placeholder="Зробіть вибір"
                    :show-labels="false"
                    :options="Object.values($store.getters.getInterface)"
                    v-model="changeGenerals.iface_id"
                >
                </multiselect>
                <small v-if="!$v.changeGenerals.iface_id.required" class="form-text errorText w-100">Виберіть
                  інтерфейс</small>
              </div>
              <div class="form-item" :class="{'errorInput': $v.changeGenerals.protocol_id.$error}">
                <CCardHeader>
                  Протокол
                  <CSpinner v-if="!$store.getters.getProtocolLength" color="primary" size="sm"/>
                </CCardHeader>
                <multiselect
                    :class=" {
                      'error': $v.changeGenerals.protocol_id.$error
                    }"
                    @change="$v.changeGenerals.protocol_id.$touch()"
                    placeholder="Зробіть вибір"
                    :show-labels="false"
                    :options="Object.values($store.getters.getProtocol)"
                    v-model="changeGenerals.protocol_id"
                >
                </multiselect>
                <small v-if="!$v.changeGenerals.protocol_id.required" class="form-text errorText w-100">Виберіть
                  протокол</small>
              </div>
            </CCard>
          </CCardGroup>
          <hr>
          <CCardGroup>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.is_equipment.$error}">
                <CCardHeader class="mb-4 border-0">
                  Потрібно обладнання?
                </CCardHeader>
                <multiselect
                    :class=" {
                      'error': $v.is_equipment.$error
                    }"

                    placeholder="Зробіть вибір"
                    :show-labels="false"
                    :options="['Так','Ні']"
                    @change="changeIsEquipment, $v.is_equipment.$touch()"
                    v-model="is_equipment"
                >
                </multiselect>
                <small v-if="!$v.is_equipment.required" class="form-text errorText w-100">Зробіть вибір</small>
              </div>
              <span v-if="is_equipment.valueOf() === 'Так'">

                          <multiselect
                              required
                              class="mt-2"
                              :show-labels="false"
                              :multiple="true"
                              placeholder="Виберіть обладнання"
                              :options="['медіаконвертор','switch / router','SFP','SFP +','QSFP','DSL-модем','3G-модем','DOCSIS-модем','Інше']"
                              @select="showExtra()"
                              @close="showExtra()"
                              @input="showExtra()"
                              v-model="offers.extra"
                          >
                          </multiselect>
                          <span class="mt-3 ml-1" v-if="show_new_extra_Input" style="font-size: .7rem;">Введіть обладнання, розділяючи комами</span>
                          <CInput
                              placeholder="Введіть обладнання"
                              v-if="show_new_extra_Input"
                              v-model="new_extra"
                          >
                        </CInput>
                          <CCardHeader>
                            Хто надає обладнання?
                          </CCardHeader>
                        <multiselect
                            placeholder="Зробіть вибір"
                            :options="['Провайдер','Банк']"
                            v-model="offers.whose_equipment"
                            :show-labels="false"
                        >
                        </multiselect>
                            <span v-if="offers.whose_equipment.valueOf()=== 'Провайдер'">
                                <CCardHeader>
                            Умови передачі обладнання?
                        </CCardHeader>
                        <multiselect
                            placeholder="Зробіть вибір"
                            :options="['Продаж','Оренда']"
                            :show-labels="false"
                            v-model="offers.equipment_transfer"
                        >
                        </multiselect>
                            </span>
                        </span>
            </CCard>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.offers.days_to_connect.$error}">
                <CCardHeader class="border-0">
                  Необхідний час для організації канала<br><span style="font-size: .7rem;">К-сть робочих днів</span>
                </CCardHeader>
                <CInput
                    class="mb-0 pt-1"
                    placeholder="К-сть робочих днів"
                    v-model="offers.days_to_connect"
                    @change="$v.offers.days_to_connect.$touch()"
                    :class="{'error': $v.offers.days_to_connect.$error}"
                >
                </CInput>
                <small
                    v-if="!$v.offers.days_to_connect.required"
                    class="form-text errorText w-100"
                >
                  Введіть терміни підключення (макс. термін підключення)
                </small>
                <small
                    v-if="!$v.offers.days_to_connect.numeric"
                    class="form-text errorText w-100"
                >
                  Введіть, будь ласка, ціле число
                </small>
                <small class="form-text errorText w-100" v-if="!$v.offers.days_to_connect.minLength">Мінімальна
                  кількістьсть цифр
                  ({{ $v.offers.days_to_connect.$params.minLength.min }}) !</small>

                <small class="form-text errorText w-100" v-if="!$v.offers.days_to_connect.maxLength">Максимальна
                  кількістьсть цифр
                  ({{ $v.offers.days_to_connect.$params.maxLength.max }}) !</small>


              </div>

              <CCardHeader>Примітка</CCardHeader>
              <CTextarea v-model="offers.note" cols="15" rows="3"
                         placeholder="Додаткова інформація по підключенню"></CTextarea>
            </CCard>
          </CCardGroup>
        </CForm>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol col="3">
            <CButton
                color="secondary"
                class="px-4 mr-1"
                @click="goBack"
            >
              Назад
            </CButton>
          </CCol>
          <CCol></CCol>
          <CCol col="7" xl="4" md="4" lg="4" class="text-right">
            <template>
              <CButton
                  v-if="loadingBlock.loadingUpdate"
                  color="primary"
                  class="btn-block "
                  disabled
              >
                <div class="  align-items-center">
                  <CSpinner color="default" size="sm"/>
                </div>
              </CButton>
              <CButton
                  v-else
                  color="primary"
                  class="px-4 btn-block"
                  @click="isValidationOffer"
              >
                {{ is_edit_offers ? 'Оновити' : 'Подати пропозицію' }}
              </CButton>
            </template>

          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </CRow>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import {required, minLength, numeric, maxLength, decimal, or} from 'vuelidate/lib/validators'
import {routerGoToName} from "@/utils/routerUtils";

export default {
  components: {
    Multiselect,
  },
  name: "TenderOffer",
  data: () => {
    return {
      order: null,
      wasValidated: false,
      failed: false,
      failed_message: "Сталася помилка, спробуйте пізніше!",
      loadingBlock: {
        loadingUpdate: false,
        loading_submit_offer: false,
      },
      is_edit_offers: false,
      offers: {
        id: "",
        connection_cost: "",
        monthly_payment: "",
        download_speed: "",
        upload_speed: "",
        type_channel: "",
        days_to_connect: "",
        physics_id: "",
        iface_id: "",
        protocol_id: "",
        is_equipment: false,
        whose_equipment: "",
        equipment_transfer: "",
        extra: [],
        order_id: "",
        provider_id: "",
        note: '',
      },
      new_connect_type: "",
      new_upload_speed: "",
      new_download_speed: "",
      new_extra: "",
      show_new_extra_Input: false,
      is_equipment: "",
      changeGenerals: {
        physics_id: '',
        iface_id: '',
        protocol_id: '',
      },
    }
  },
  validations: {
    offers: {
      connection_cost: {
        required,
        or: or(numeric, decimal),
        minLength: minLength(1),
        maxLength: maxLength(15)
      },
      monthly_payment: {
        required,
        or: or(numeric, decimal),
        minLength: minLength(1),
        maxLength: maxLength(15)
      },
      upload_speed: {
        required,
        minLength: minLength(1)
      },
      download_speed: {
        required,
        minLength: minLength(1)
      },
      type_channel: {
        required,
        minLength: minLength(1)
      },
      days_to_connect: {
        required,
        numeric,
        minLength: minLength(1),
        maxLength: maxLength(3)
      }
    },
    changeGenerals: {
      physics_id: {
        required,
        minLength: minLength(1)
      },
      iface_id: {
        required,
        minLength: minLength(1)
      },
      protocol_id: {
        required,
        minLength: minLength(1)
      },
    },
    is_equipment: {
      required,
    },
  },
  methods: {
    showTypeChannel(ConnectType) {
      if (ConnectType === 'Інтернет') {
        return;
      }
      if (ConnectType !== 'Інше') {
        this.new_connect_type = ""
      }
    },
    changeUploadSpeed() {
      if (this.offers.upload_speed === 'Інше') {
        // this.offers.upload_speed = this.new_upload_speed;
        this.offers.upload_speed = Number(parseFloat(this.new_upload_speed.toString().replace(',', '.')).toFixed(2));

        return;
      }
      this.new_upload_speed = "";
    },
    showExtra() {
      let self = this;
      self.offers.extra.forEach(function (item) {
        if (item === 'Інше') {
          self.show_new_extra_Input = true;
        }
      });
      if (!self.offers.extra.includes('Інше')) {
        self.new_extra = "";
        self.show_new_extra_Input = false;
      }

    },
    changeDownloadSpeed() {
      if (this.offers.download_speed === 'Інше') {
        // this.offers.download_speed = this.new_download_speed;
        this.offers.download_speed = parseFloat(this.new_download_speed.toString().replace(',', '.')).toFixed(2);
        return;
      }
      this.new_download_speed = "";
    },
    changeIsEquipment() {
      if (this.is_equipment.valueOf() === "Так") {
        this.offers.is_equipment = true;
      }
      if (this.is_equipment.valueOf() === "Ні") {
        this.offers.is_equipment = false;
        this.offers.equipment_transfer = "";
        this.offers.extra = "";
        this.offers.whose_equipment = "";
        this.offers.equipment_transfer = "";
      }
    },
    formatOffer() {

      this.offers.provider_id = this.$store.state.organization.id;
      // self.offers.connection_cost = Number(self.offers.connection_cost);
      this.offers.connection_cost = Number(parseFloat(this.offers.connection_cost.toString().replace(',', '.')).toFixed(2));
      // self.offers.monthly_payment = Number(self.offers.monthly_payment);
      this.offers.monthly_payment = Number(parseFloat(this.offers.monthly_payment.toString().replace(',', '.')).toFixed(2));
      // self.offers.download_speed = self.offers.download_speed;
      if (this.offers.download_speed !== "Інше") {
        this.offers.download_speed = Number(parseFloat(this.offers.download_speed.toString().replace(',', '.')).toFixed(2));
      }
      // self.offers.upload_speed = self.offers.upload_speed;
      if (this.offers.upload_speed !== "Інше") {
        this.offers.upload_speed = Number(parseFloat(this.offers.upload_speed.toString().replace(',', '.')).toFixed(2));
      }
      this.offers.days_to_connect = Number(this.offers.days_to_connect);
      this.offers.extra = this.offers.extra + this.new_extra;


      this.offers.physics_id = Number(Object.keys(this.$store.getters.getPhysics).find(key => this.$store.getters.getPhysicsByID(key) === this.changeGenerals.physics_id));
      this.offers.iface_id = Number(Object.keys(this.$store.getters.getInterface).find(key => this.$store.getters.getInterfaceByID(key) === this.changeGenerals.iface_id));
      this.offers.protocol_id = Number(Object.keys(this.$store.getters.getProtocol).find(key => this.$store.getters.getProtocolByID(key) === this.changeGenerals.protocol_id));
    },
    changeOther() {
      if (this.offers.type_channel === 'Інше') {
        this.offers.type_channel = this.new_connect_type;
      }
      if (this.offers.download_speed === 'Інше') {
        // this.offers.download_speed=this.new_download_speed;
        this.offers.download_speed = Number(parseFloat(this.new_download_speed.toString().replace(',', '.')).toFixed(2));
      }
      if (this.offers.upload_speed === 'Інше') {
        // this.offers.upload_speed=this.new_upload_speed;
        this.offers.upload_speed = Number(parseFloat(this.new_upload_speed.toString().replace(',', '.')).toFixed(2));
      }
    },
    isValidationOffer() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.offers.extra = this.offers.extra.toString();
        this.loadingBlock.loading_submit_offer = true;
        if (!this.offers.order_id) {
          this.offers.order_id = this.$route.params.order_id;
        }

        this.formatOffer();
        this.changeOther();
        this.changeIsEquipment();

        this.offers.extra = this.offers.extra.toString();

        if (!this.is_edit_offers) {
          this.createOffer();
        } else {
          this.updateOffer();
        }
      }
    },
    createOffer() {
      let self = this;
      self.failed = false;
      axios.post('/api/offers', self.offers)
          .then(function (response) {
            if (response.status === 201) {
              self.$router.push({path: `/tenders/${self.$route.params.order_id}`});
            }
            self.loadingBlock.loading_submit_offer = false;
          })
          .catch(function () {
            self.failed = true;
            self.loadingBlock.loading_submit_offer = false;
          })
    },
    updateOffer() {
      let self = this;
      self.loadingBlock.loadingUpdate = false;
      self.failed = false;
      axios.put('/api/offers/' + self.offers.id, self.offers)
          .then(function () {
            self.loadingBlock.loadingUpdate = false;
            self.$router.push({path: `/tenders/${self.offers.order_id}`});
          })
          .catch(function () {
            self.failed = true;
            self.loadingBlock.loadingUpdate = false;
          })
    },

    goBack() {
      // this.$router.go(-1);
      // this.$router.push({path: `/tenders/${this.offers.order_id}`});
      routerGoToName('TendersPreview', 'params', this.offers.order_id);
    },
    getOrderByID() {
      let self = this;
      axios.get(`/api/account/orders/${self.$route.params.order_id ? self.$route.params.order_id : self.offers.order_id}`).then(function (response) {
        self.order = response.data;
      }).catch(function () {
      });
    },
    getOfferByID() {
      let self = this;
      axios.get(`/api/account/offers/${this.$route.params.id}`)
          .then(function (response) {
            self.offers = response.data;
            self.offers.extra = self.offers.extra.split(',');
            self.changeIsEquipmentBool();
            self.getOrderByID();
          })
          .catch(function () {
          })
    },
    changeIsEquipmentBool() {
      if (this.offers.is_equipment === true) {
        this.is_equipment = "Так"
      }
      if (this.offers.is_equipment === false) {
        this.is_equipment = "Ні";
      }
    },
    updateGeneral(newValue) {
      const physicsKey = Object.keys(newValue.physics);
      const protocolKey = Object.keys(newValue.protocol);
      const interfacesKey = Object.keys(newValue.interfaces);

      physicsKey.forEach(key => {
        // Object.keys(object).find(key => object[key] === value);

        if (Number(key) === Number(newValue.offers.physics_id)) {
          this.changeGenerals.physics_id = this.$store.getters.getPhysicsByID(key);
          return this.changeGenerals.physics_id;
        }
      });
      protocolKey.forEach(key => {
        // Object.keys(object).find(key => object[key] === value);
        if (Number(key) === Number(newValue.offers.protocol_id)) {
          this.changeGenerals.protocol_id = this.$store.getters.getProtocolByID(key);
          return this.changeGenerals.protocol_id;
        }
      });
      interfacesKey.forEach(key => {
        // Object.keys(object).find(key => object[key] === value);
        if (Number(key) === Number(newValue.offers.iface_id)) {
          this.changeGenerals.iface_id = this.$store.getters.getInterfaceByID(key);
          return this.changeGenerals.iface_id
        }
      });
    }
  },
  created() {
    this.$store.dispatch('GET_ORGANIZATION');
    if (!this.$store.getters.getPhysicsLength) this.$store.dispatch('GET_Physics');
    if (!this.$store.getters.getInterfaceLength) this.$store.dispatch('GET_Interface');
    if (!this.$store.getters.getProtocolLength) this.$store.dispatch('GET_Protocol');

    this.getOrderByID();
    if (this.$route.params.id) {
      this.is_edit_offers = true;
      this.getOfferByID();
    }
  },
  computed: {
    updateMultiSelect() {
      return {
        physics: this.$store.getters.getPhysics,
        protocol: this.$store.getters.getProtocol,
        interfaces: this.$store.getters.getInterface,
        offers: this.offers,
      }
    }
  },
  watch: {
    updateMultiSelect(newValue, oldVal) {
      console.log(newValue);
      this.updateGeneral(newValue)
    },
  }
}
</script>
<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #cb203d !important;
  border-style: solid !important;
  border-width: 2px !important;

}
</style>
